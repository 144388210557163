<template>
    <b-container fluid>
        <iq-card class="mt-2" v-if="isOrgAdmin === 1 ? true : false">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('pump_install.pump_install_dashboard') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col lg="12" class="d-flex justify-content-center mb-1">
                                <h4 class="font-weight-bolder" style="color: #ddd;">{{ currentOrgName }}</h4>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <iq-card class="mt-2" v-else-if="upazilaId">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('pump_install.pump_install_dashboard') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col lg="4" class="text-center mb-1">
                                <div>
                                    <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.district') }} : {{ getDistrictName }}</h4>
                                </div>
                            </b-col>
                            <b-col lg="4" class="text-center mb-1">
                                <div>
                                    <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.upazila') }} : {{ upazilaName }}</h4>
                                </div>
                            </b-col>
                            <b-col lg="4" class="text-center mb-1">
                                <div>
                                    <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('pump_install.office') }} : {{ getOfficeName }}</h4>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <iq-card v-else>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('pump_install.pump_install_dashboard') }}</h4>
            </template>
            <template v-slot:body>
                <b-row class="mb-4">
                    <b-col>
                        <b-row>
                            <b-col lg="12" class="d-flex justify-content-center mb-4">
                                <h4 class="font-weight-bolder">{{ $t('globalTrans.welcomeToAdminDashboard') }}</h4>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="2"></b-col>
                            <b-col lg="6" md="6" sm="12" xm="12">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label="$t('globalTrans.selectOrg')"
                                    label-for="org_id"
                                >
                                    <b-form-select
                                        plain
                                        v-model="orgId"
                                        :options="orgList"
                                        id="org_id"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4">
                                <b-button type="button" variant="primary" @click="filterData()">{{ $t('globalTrans.search')}}</b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </template>
        </iq-card>

        <b-overlay :show="loadingState">
            <b-row>
                <div class="cust-card-col col-lg-4" v-for="(item, key) in items" :key="key" lg="4">
                    <router-link :to="item.url">
                        <div class="iq-card">
                            <div class="iq-card-body">
                                <div class="text-center">
                                    <span class="title" :class="item.class"> {{ item.title ? (item.title.search('.') !== -1 ? $t(item.title) : item.title) : '' }} </span>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="value-box">
                                        <h2 class="mb-0">
                                            <span class="counter" style="visibility: visible;">{{ $n(item.value) }}</span>
                                        </h2>
                                    </div>
                                </div>

                                <div class="float-right iq-iconbox mt-3" :class="item.class">
                                    <i :class="item.icon"></i>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </b-row>
        </b-overlay>
    </b-container>
</template>

<script>
    import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
    import { pumpInstallDashboardOverview } from '../../api/routes'

    export default {
        name: 'PumpInstallationDashboard',
        data () {
            return {
                loadingState: false,
                orgId: 0,
                isOrgAdmin: false,
                upazilaId: 0,
                officeName: '',
                districtId: 0,
                currentOrg: {},
                dashboardData: {
                    schemeApp: 0,
                    installedPump: 0,
                    rejectedPump: 0,
                    optApp: 0,
                    approvedOptApp: 0,
                    licencedScheme: 0
                }
            }
        },
        computed: {
            currentOrgName () {
                return (this.$i18n.locale === 'bn') ? this.currentOrg.text_bn : this.currentOrg.text
            },
            upazilaName () {
                let returnVal = ''
                const upazilas = this.$store.state.commonObj.upazilaList
                const upazila = upazilas.find(el => el.value === this.upazilaId)
                if (upazila) {
                    returnVal = (this.$i18n.locale === 'bn') ? upazila.text_bn : upazila.text
                }
                return returnVal
            },
            getDistrictName () {
                const district = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(this.districtId))
                if (typeof district === 'undefined') {
                    return ''
                } else {
                    return district.text
                }
            },
            getOfficeName () {
               return (this.officeName) ? this.officeName : ''
            },
            orgList () {
                return this.$store.state.commonObj.organizationProfileList
            },
            items () {
                return [
                    {
                        title: 'pump_install.total_scheme_application',
                        value: this.dashboardData.schemeApp,
                        icon: 'far fa-address-card',
                        class: 'color-blue',
                        url: '/irrigation-scheme-service/pump-installation/application-list'
                    },
                    {
                        title: 'pump_install.total_installed_pump',
                        value: this.dashboardData.installedPump,
                        icon: 'fas fa-tools',
                        class: 'color-green',
                        url: '/irrigation-scheme-service/pump-installation/application-list?status=14'
                    },
                    {
                        title: 'pump_install.total_rejected_scheme',
                        value: this.dashboardData.rejectedPump,
                        icon: 'far fa-times-circle',
                        class: 'color-orange',
                        url: '/irrigation-scheme-service/pump-installation/application-list?status=5'
                    },
                    {
                        title: 'pump_install.total_licensed_scheme',
                        value: this.dashboardData.licencedScheme,
                        icon: 'fas fa-user-tag',
                        class: 'color-purple',
                        url: '/irrigation-scheme-service/pump-installation/application-list'
                    },
                    {
                        title: 'pump_install.total_operator_application',
                        value: this.dashboardData.optApp,
                        icon: 'fas fa-user-tie',
                        class: 'color-yellow',
                        url: '/irrigation-scheme-service/pump-installation/pump-operator-application-list'
                    },
                    {
                        title: 'pump_install.total_approved_operator',
                        value: this.dashboardData.approvedOptApp,
                        icon: 'fas fa-user-check',
                        class: 'color-green',
                        url: '/irrigation-scheme-service/pump-installation/pump-operator-application-list?status=3'
                    }
                ]
            }
        },
        created () {
            this.checkUser()
        },
        methods: {
            checkUser () {
                const user = this.$store.state.Auth.authUser
                this.isOrgAdmin = user.is_org_admin
                if (user.role_id === 0 && user.is_org_admin === 1) {
                    this.orgId = user.org_id
                    this.filterData()
                    const org = this.$store.state.orgList.find(el => el.value === user.org_id)
                    if (org) {
                        this.currentOrg = org
                    }
                } else if (user.role_id === 0) {
                    this.orgId = user.office_detail.org_id
                    this.districtId = user.office_detail.district_id
                    this.upazilaId = user.office_detail.upazilla_id
                    this.officeName = (this.$i18n.locale === 'bn') ? user.office_detail.office_name_bn : user.office_detail.office_name
                    this.filterData()
                } else {
                    this.filterData()
                }
            },
            filterData () {
                this.loadingState = true
                RestApi.getData(irriSchemeServiceBaseUrl,
                    pumpInstallDashboardOverview + '?org_id=' + this.orgId + '&far_upazilla_id=' + this.upazilaId)
                .then(response => {
                    this.loadingState = false
                    if (response.success) {
                        this.dashboardData.schemeApp = response.data.totalScheme || 0
                        this.dashboardData.installedPump = response.data.schemeByStatus.installed || 0
                        this.dashboardData.rejectedPump = response.data.schemeByStatus.rejected || 0
                        this.dashboardData.optApp = response.data.operatorTotal || 0
                        this.dashboardData.approvedOptApp = response.data.approvedOperator || 0
                        this.dashboardData.licencedScheme = response.data.totalLicensedScheme || 0
                    }
                }).catch((error) => {
                    if (error.response) {
                        this.loadingState = false
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .iq-iconbox{
        width: 45px !important;
        height: 45px !important;
    }
    .cust-card-col {
        margin-bottom: 2rem;
    }
    .title.color-green {
        $font-color: #368428;
        color: $font-color;
    }
    .title.color-orange {
        $font-color: #ec6323;
        color: $font-color;
    }
    .title.color-yellow {
        $font-color: #ab8e01;
        color: $font-color;
    }
    .title.color-purple {
        $font-color: #5d00c7;
        color: $font-color;
    }
    .title.color-blue {
        $font-color: #0e71f3;
        color: $font-color;
    }

    .iq-iconbox {
        position: absolute;
        right: 52px;
        top: 30px;
    }

    .iq-iconbox.color-green {
        $font-color: #368428;
        color: $font-color;
        background: lighten($font-color, 40%) !important;
    }

    .iq-iconbox.color-orange {
        $font-color: #ec6323;
        color: $font-color;
        background: lighten($font-color, 40%) !important;
    }

    .iq-iconbox.color-yellow {
        $font-color: #ab8e01;
        color: $font-color;
        background: lighten($font-color, 40%) !important;
    }

    .iq-iconbox.color-purple {
        $font-color: #5d00c7;
        color: $font-color;
        background: lighten($font-color, 40%) !important;
    }
    .iq-iconbox.color-blue {
        $font-color: #0e71f3;
        color: $font-color;
        background: lighten($font-color, 40%) !important;
    }

    .cust-card-col .iq-card {
        padding: 0;
        transition: box-shadow 300ms;
        cursor: pointer;
        border-radius: 10px;

        -webkit-box-shadow: 0px 0px 10px -2px rgba(191,191,191,1);
        -moz-box-shadow: 0px 0px 10px -2px rgba(191,191,191,1);
        box-shadow: 0px 0px 10px -2px rgba(191,191,191,1);

        h5 {
            text-align: center;
            font-weight: 600;
            font-size: 22px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .cust-card-col:hover .iq-card {
        -webkit-box-shadow: 0px 3px 12px -4px rgba(79,79,79,1);
        -moz-box-shadow: 0px 3px 12px -4px rgba(79,79,79,1);
        box-shadow: 0px 3px 12px -4px rgba(79,79,79,1);
        transition-timing-function: ease-in-out;
    }

    .iq-card-body .title {
        font-size: 16px;
        font-weight: 600;
    }
</style>
